import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const HLSPlayer = () => {
  const videoRef = useRef(null);
  const hlsUrl = "https://f53112b70bc31005.mediapackage.ap-south-1.amazonaws.com/out/v1/e3c88461dd8e4001bc9e4d9805de20d5/index.m3u8";

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(hlsUrl);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log("HLS manifest loaded, starting playback.");
        videoRef.current.play();
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error("HLS Error:", data);
      });

      return () => {
        hls.destroy();
      };
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      // For Safari or other browsers with native HLS support
      videoRef.current.src = hlsUrl;
      videoRef.current.addEventListener("loadedmetadata", () => {
        videoRef.current.play();
      });
    }
  }, [hlsUrl]);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <video
        ref={videoRef}
        style={{ width: "1920px", height: "1080px" }}
        controls
        autoPlay
        muted
      ></video>
    </div>
  );
};

export default HLSPlayer;
