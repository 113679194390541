import React from 'react';
import Home from './Home';
import ReactDOM from "react-dom";
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import IndexPage from './IndexPage'; // Create this component for the source URL
import Contact from './Contact';
import About from './About';
import Services from './Services';
import Event from './Event';
import TechnicalSupport from './TechnicalSupport';
import Terms from './Terms';
import Career from './Career';
import Privacy from './Privacy';
import Category from './Category';
import Search from './Search';
import Detail from './Detail';
import AllPhotos from './AllPhotos';
import EnglishWire from './EnglishWire'
import EnglishWireSearch from './EnglishWireSearch'
import VideoWire from './VideoWire'
// import VideoWire from './VideoWireShort'
import VideoWireSearch from './VideoWireSearch'
import PhotoWire from './PhotoWire'
import PhotoWireSearch from './PhotoWireSearch'
import AllPhotoWireSearch from './AllPhotoWireSearch'
import HindiWire from './HindiWire'
import HindiWireSearch from './HindiWireSearch'
import Login from './Login'
import Stories from './Stories'
import EnglishWireDetail from './EnglishWireDetail'
import BreakingNews from './BreakingNews'
import PhotoWireDetail from './PhotoWireDetail'
import HomePage from './HomePage'
import HindiWireDetail from './HindiWireDetail'
import VideoWireDetail from './VideoWireDetail'
import Permision from './Permision'
import PhotoEvent from './PhotoEvent'
import AllPhotoWire from './AllPhotoWire'
import VideoShortWire from './VideoShortWire'
import NewsWire from './NewsWire'
//import DarkTheme from './darkTheme'
import NewsWireDetail from './NewsWireDetail'
import BusinessWire from './BusinessWire'
import BusinessWireDetail from './BusinessWireDetail'
import NewsReach from './NewsReach'
import NewsReachDetail from './NewsReachDetail'
import ElectionResult from './ElectionResult'
import CabinetMinisters from './CabinetMinisters'
//import '../css/style.css';
import VideoWireIans from './VideoWireIans'
import JustIn from './JustIn'
import JDetail from './JDetail'
import JustInNew from './JustInNew'
import VideoElementDetail from './VideoElementDetail'
import VideoElements from './VideoElements'
import HLSPlayer from "./HLSPlayer";
const App = () =>{
  return(
    <>
     
    <BrowserRouter>
    {/* <DarkTheme /> */}
      <Switch>
        <Route exact path="/english-wire/" component={EnglishWire}/>

        <Route exact path="/">
        <Redirect to= "/english-wire/all/" />
      </Route>
        <Route exact path="/lok-sbabha-election-results-2024" component={ElectionResult} />
        <Route exact path="/all-photos" component={AllPhotos}/>
        <Route exact path="/stories" component={Stories}/>
        <Route exact path="/permision" component={Permision} />
        <Route exact path="/english-wire/all" component={EnglishWire}/>
        <Route exact path="/english-wire/:categoryId" component={EnglishWire}/>
        <Route exact path="/just-in/:newsId" component={JDetail}/>
        <Route exact path="/video-wire/just_in" component={JustIn}/>
        <Route exact path="/video-wire/just_in/:offset" component={JustIn}/>

        <Route exact path="/video-wire/just_innew" component={JustInNew}/>
        <Route exact path="/video-wire/just_innew/:offset" component={JustInNew}/>

        <Route exact path="/breaking-news" component={BreakingNews}/>
        <Route exact path="/breaking-news/:offset" component={BreakingNews}/>   
        <Route exact path="/pr-news-wire" component={NewsWire}/>
        <Route exact path="/pr-news-wire/:offset" component={NewsWire}/>
        <Route exact path="/ians-live" component={HLSPlayer}/>

        <Route exact path="/business-wire" component={BusinessWire}/>
        <Route exact path="/business-wire/:offset" component={BusinessWire}/>  

        <Route exact path="/vmpl-news" component={NewsReach}/>
        <Route exact path="/vmpl-news/:offset" component={NewsReach}/>  

        <Route exact path="/hindi-wire/all" component={HindiWire}/>
        <Route exact path="/hindi-wire/:categoryId" component={HindiWire}/>

        <Route exact path="/video-wire/special_ians" component={VideoWireIans}/>
        <Route exact path="/video-wire/special_ians/:offset" component={VideoWireIans}/>

        <Route exact path="/video-wire/video_elements" component={VideoElements}/>
        <Route exact path="/video-wire/video_elements/:offset" component={VideoElements}/>


        <Route exact path="/video-wire/all" component={VideoWire}/>
        <Route exact path="/video-wire/:categoryId" component={VideoWire}/>
        <Route exact path="/video-wire/:categoryId/:offset" component={VideoWire}/>
        {/* <Route exact path="/video-wire/short_videos/:offset" component={VideoShortWire}/> */}

        {/* <Route exact path="/video-wire/short" component={VideoWireShort}/>    */}
       
        <Route exact path="/photo-wire/all" component={PhotoWire}/>
        <Route exact path="/photo-wire/:categoryId" component={PhotoWire}/>
        <Route exact path="/photo-wire/:categoryId/:offset" component={PhotoWire}/>   

        <Route exact path="/all-photo-wire/all" component={AllPhotoWire}/>
        <Route exact path="/all-photo-wire/:categoryId" component={AllPhotoWire}/>
        <Route exact path="/all-photo-wire/:categoryId/:offset" component={AllPhotoWire}/>              
        {/* <Route exact path="/english-wire/:categoryId/:search/:offset" component={EnglishWire}/>         */}
        {/* <Route exact path="/english-wire/:search/:offset" component={EnglishWire}/>         */}

        {/* <Route exact path="/english-wire/:offset" component={EnglishWire}/> */}
        {/* 
        <Route exact path="/english-search/:search/:offset" component={EnglishWireSearch}/> */}
         {/* <Route exact path="/english-search/:offset" component={EnglishWireSearch}/> */}
         <Route exact path="/english-search/:search" component={EnglishWireSearch}/> 
        <Route exact path="/photo-search/:search" component={PhotoWireSearch}/>
        <Route exact path="/photo-search/:search/:offset" component={PhotoWireSearch}/>

        <Route exact path="/all-photo-search/:search" component={AllPhotoWireSearch}/>
        <Route exact path="/all-photo-search/:search/:offset" component={AllPhotoWireSearch}/>

        {/* <Route exact path="/hindi-search/:offset" component={HindiWireSearch}/> */}

        <Route exact path="/hindi-search/:search" component={HindiWireSearch}/>
        {/* <Route exact path="/hindi-search/:search/:offset" component={HindiWireSearch}/>  */}

        <Route exact path="/video-search/:search" component={VideoWireSearch}/>
        <Route exact path="/video-search/:search/:offset" component={VideoWireSearch}/>

        <Route exact path="/contact-us" component={Contact}/>
        <Route exact path="/services" component={Services}/>
        <Route exact path="/technical-support" component={TechnicalSupport}/>
        <Route exact path="/about-us" component={About}/>
        <Route exact path="/terms-of-use" component={Terms}/>
        <Route exact path="/career" component={Career}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/privacy-policy" component={Privacy}/>
        <Route exact path="/cabinet-ministers" component={CabinetMinisters}/>

        <Route exact path="/category/:categoryId" component={Category}/>
        <Route exact path="/category/:categoryId/:offset" component={Category}/>
        <Route exact path="/english-wire-detail/:newsId" component={EnglishWireDetail}/>
        <Route exact path="/photo-wire-detail/:photoId" component={PhotoWireDetail}/>

        <Route exact path="/hindi-wire-detail/:newsId" component={HindiWireDetail}/>

        <Route exact path="/video-element-detail/:newsId" component={VideoElementDetail}/>
        <Route exact path="/video-wire-detail/:newsId" component={VideoWireDetail}/>

        <Route exact path="/pr-wire-detail/:newsId" component={NewsWireDetail}/>
        <Route exact path="/business-wire-detail/:newsId" component={BusinessWireDetail}/>
        <Route exact path="/vmpl/:newsId" component={NewsReachDetail}/>

        {/* <Route exact path="/detail/:newsId" component={Detail}/> */}
        <Route exact path="/photo-event/:newsId" component={PhotoEvent}/>

        {/* <Route exact path="/event/:newsId" component={Event}/> */}
        <Route path="/index.php" component={IndexPage} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
    </>
  )
}

export default App;
