import React,{useState, useEffect,useRef, useCallback} from 'react'
import axios from 'axios'
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom'
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns'
import { useHistory } from "react-router-dom"
import HomeSlider from './HomeSlider'
import Top from './Top'
import HeadingTab from './HeadingTab'


const HindiWireSearch = ()=>{
const [cateData, setCateData] = useState({})
const [searchHead, SetSHead] = useState('template-search')
const [data, setData] = useState([]);
const [page, setPage] = useState(1);
const [loading, setLoading] = useState(false);
const observer = useRef();
const [engData, setEngData] = useState([])
const categoryList = [
    {
        name:'राष्ट्रीय',
        slug:'national'
    },
    {
        name:'अंतर्राष्ट्रीय',
        slug:'international'
    },
    {
        name:'व्यापार/अर्थव्यवस्था',
        slug:'business'
    },
    {
        name:'सिनेमा/मनोरंजन/फैशन',
        slug:'entertainment'
    },
    {
        name:'विज्ञान/प्रौद्योगिकी',
        slug:'science-tech'
    },
    {
        name:'खेल',
        slug:'sports'
    },
    {
        name:'स्वास्थ्य/चिकित्सा',
        slug:'health-medicine'
    }    
]
const [cateText, setCategoryName] = useState('')
const [cateName, setCateName] = useState('')
const [searchText, setSearchText] = useState('')
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)
const {search, offset,} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5)
const history = useHistory()
const [requestFlag, setRequestFlag] = useState(true)
const [latData, setLatestData] = useState();

const LatestData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/news/list/?language=hindi`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.slice(0, 6).map((item) => {
          let tgSt= item.tags.filter(it => it.name === 'Business')
 
        return {
            id: item.id,
            image: item.thumbnail,
            title: item.title,
            slug:'/hindi-wire-detail/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): ''
        }})
    setLatestData(latestStories)
    })
 }
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
const getCatgoryData=(obj)=>{
    if(obj != cateText){
        setCategoryName(obj)
        setCurrentPage(1)
        history.push('/hindi-wire/1');
    }
    //fetchData()
}
const searchData=(data)=>{
    setSearchText(data)
    history.push('/hindi-search/'+data);

}

const fetchData = async (page) => {
    let searchkey = localStorage.Hsearch;   
    let getStories = []
    page = page ? page: 1;
    let getApii = `https://cms.iansnews.in/api/elastic/news/search/?language=hindi`;
    if(searchkey){
        //cateText = `&tags=${cateText}`;
        getApii += `&query=${searchkey}`;
    }
    // if(inputSearch){
    //     getApii += `&query=${inputSearch}`;
    // }
    getApii += `&page=${page}`;

    try {
        const res = await axios.get(getApii);
    
        const getStories = res.data.results && res.data.results.length > 0
          ? res.data.results.map((item) => {
              return {
                byline: item.byline,
                content: item.short_desc,
                id: item.id,
                image: item.thumbnail ? item.thumbnail : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
                image_count: item.image_count,
                dateTime: item.created_at ? formatDate(item.created_at) : '',
                title: item.title,
                tags: item.tags,
                slug: '/hindi-wire-detail/' + item.slug,
                short_desc: item.short_desc,
                video_preview: item.video_url ? item.video_url : '',
                image_caption: item.image_caption
              };
            })
          : [];
    
        // Get existing ids from engData
        const existingIds = new Set(engData.map(item => item.id));
    
        // Filter out duplicates
        const uniqueStories = getStories.filter(story => !existingIds.has(story.id));
    
        if (uniqueStories.length > 0) {
          setEngData((prevEngData) => [...prevEngData, ...uniqueStories]);
        }
      } catch (err) {
        // Handle the error
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
}

const handleNextPage = () => {
    if(lastPage > currentPage){
        setCurrentPage(parseInt(currentPage) + 1)
    }
}

const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const handlePrevPage = () => {
    if(currentPage > 1){
        setCurrentPage(currentPage - 1);
    }
}
const inputSData=(event)=>{
    localStorage.removeItem(inputSearch)
    setSearch(event.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    localStorage.removeItem(inputSearch)

   // alert(inputSearch)
    localStorage.setItem('Hsearch', inputSearch ? inputSearch :'')
    let path = '/hindi-search'
    //  SetSHead('template-search')
      //window.reload()
      if(inputSearch && inputSearch != 'undefined'){
        window.location.pathname=path+'/'+inputSearch;
        history.push(path);
      }
    

}
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
        setIsVisible(true)
    } else {
        setIsVisible(false)
    }
}
const [startPg, setStartPage] = useState(1)
const inputGSData=(event)=>{
    setStartPage(event.target.value)

  }
const GoToPage=(e)=>{
    e.preventDefault()
    setCurrentPage(startPg);
    let pathh = '/hindi-search'
    

    history.push(pathh)
    
}
useEffect(() => {

    fetchData(page)
   // getTopNewsData()
    LatestData()
  }, [page, search]);
const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
                // if(categoryId != cateText){
                //     setPage(1)
                // }else{
                //     setPage((prevPage) => prevPage + 1);
                // }
                setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );
return(
<>
<Helmet>
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
   <meta data-meta-title="IANS IN" name="keywords" content={''} />
   <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
</Helmet>
<Header />
        <HomeSlider/>
        {/* <Top  title="TOP HINDI" data={latData} vTag={false}/> */}

        <main id="main">
            <section id="about" className="about">
            <div className="container">
                <HeadingTab />
                <div className="row">
                        <div className="col-lg-9 tab-content left-padding" id="myTabContent">
                        <ul className="top-category-list-bottom tab-pane fade show active" id="english" role="tabpanel" aria-labelledby="home-tab">
                            <div className="main-heading">
                                <h2 className="heading">Search results for - {localStorage.Hsearch ? localStorage.Hsearch.toUpperCase():''}</h2>
                            </div>

                        { engData && engData.length > 0 && engData.map((engTemp ,et)=>{
                            return(
                                <>
                                    <div className="container-fluid sb-4" key={et} ref={lastElementRef}>
                                        <div className="row g-0">
                                            <li>
                                                <figure>
                                                    <div className="img-area-top-category-list-bottom">
                                                        <NavLink to={engTemp.slug}><img src={engTemp.image} /></NavLink>
                                                    </div>
                                                    <figcaption className="tabtextarea-top-category-list-bottom">
                                                    <NavLink to={engTemp.slug}><h3 className="title">{engTemp.title}</h3></NavLink>
                                                        <div className="cat-date-wrap-1">
                                                        {engTemp.tags && engTemp.tags.length > 0 && engTemp.tags.map((tgg,t)=>{
                                                            return(
                                                            <span className="cd-cat-M" key={t}><NavLink to={`/hindi-wire/${tgg.slug}/1`}>{tgg.hindi_name}</NavLink></span>
                                                        )})}
                                                        <span className="cd-date-M">{engTemp.dateTime}</span>
                                                        </div>
                                                        <p>{engTemp.short_desc}</p>
                                                    </figcaption>
                                                </figure>
                                            </li>
                                        </div>
                                    </div>
                                </>
                        )})}

{loading && <p>Loading more posts...</p>}
                        </ul>
                    </div>
                    <div className="col-lg-3 latest-news-sec right-sec">
                    <div className="main-heading">
                                <h2 className="heading">Search</h2>
                        </div>
                        <div className="search-form-allphoto input-group">
                        <form onSubmit={onSubmit}>            
                            <input type="search" ref={inputRef} className="form-control" placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                            <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                        </form>
                        </div>
                        <div className="main-heading">
                              <h2 className="heading">ताजा खबरें</h2>
                        </div>
                        <ul className="list-unstyright">
                           {latData && latData.length > 0 && latData.map((lattt, l)=>{
                              return(
                                 <>
                                    <li key={l}>
                                       <figure>
                                          <figcaption className="tabtextarea-unstyright">
                                             <NavLink title={lattt.title} alt={lattt.title} to={lattt.slug}>
                                                <h3 className="title">{lattt.title}</h3>
                                             </NavLink>
                                          </figcaption>
                                          <div className="img-area-unstyright">
                                             <NavLink to={lattt.slug}>
                                                <img src={lattt.image} title={lattt.title} alt={lattt.title}/>
                                             </NavLink>
                                            
                                          </div>
                                       </figure>
                                    </li>
                                 </>
                              )
                           })}
                        </ul>
                    </div>
                </div>
            </div>
            </section>
        </main>               
        <Footer />  
</>
)
}
export default HindiWireSearch