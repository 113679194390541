import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom'
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns'
import { useHistory } from "react-router-dom"
import HomeSlider from './HomeSlider'
import Top from './Top'
import HeadingTab from './HeadingTab'
import {useStickyBox} from "react-sticky-box";
import VideoCat from './videoCat'
import StickySidebar from 'sticky-sidebar';
import CategoryListData from './Data/Category'
import Election from './Election';

const EnglishWire = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const observer = useRef();
  const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
  const [engData, setEngData] = useState([])

  const [cateText, setCategoryName] = useState('all')
  const [cateName, setCateName] = useState('')
  const [searchText, setSearchText] = useState('')
  const [inputSearch, setSearch] = useState()
  const inputRef = useRef(null)
  const {categoryId, search, offset,} = useParams()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageList, setPageList] = useState([1,2,3])
  const [startPg, setStartPage] = useState(1)
  const history = useHistory()
  const [latData, setLatestData] = useState();
  
  const LatestData =()=>{
      axios
      .get(
      `https://cms.iansnews.in/api/elastic/news/list/?language=english`
      )
      .then((res) => {
      const latestStories =
          res.data.results &&
          res.data.results.length > 0 &&
          res.data.results.slice(0, 6).map((item) => { 
          return {
              id: item.id,
              image: item.thumbnail,
              title: item.title,
              slug:'/english-wire-detail/'+item.slug,
              dateTime: item.created_at ? formatDate(item.created_at): ''
          }})
      setLatestData(latestStories)
      })
   }
   const [topData, setTopData] = useState();
  
  const getTopNewsData =()=>{
      axios
      .get(
      `https://cms.iansnews.in/api/elastic/news/list/?top_news=true&language=english`
      )
      .then((res) => {
      const latestStories =
          res.data.results &&
          res.data.results.length > 0 &&
          res.data.results.map((item) => {
   
          return {
              id: item.id,
              image: item.thumbnail ? item.thumbnail : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
              title: item.title,
              slug:'/english-wire-detail/'+item.slug,
              dateTime: item.created_at ? formatDate(item.created_at): ''
          }})
          setTopData(latestStories)
      })
   }
  const formatDate = (timestamp)=>{
      const date = parseISO(timestamp)
      return format(date, 'MMMM dd, yyyy h:mm a')
  }
  const getCatgoryData=(obj)=>{
      if(obj != cateText){
          setCategoryName(obj)
          setCurrentPage(1)
          history.push('/english-wire/1');
      }
      //fetchData()
  }
  const [categoryList, setCategoryList] = useState([])

  const getFilterCategories = ()=>{

    setCategoryList(CategoryListData.filter(category => category.show_on === 1))
}
const getCatgoryName = (slug) => {
    const category = CategoryListData.find(category => category.slug === slug);
    if (category) {
        // return { name: category.name, hindi_name: category.hindi_name };
         return category.name

    } else {
        return null;
    }
}
  useEffect(() => {
    if(categoryId){
        setCategoryName(getCatgoryName(categoryId))
    }else{
        setCategoryName(getCatgoryName('all'))
    }

    fetchData(page)
    getFilterCategories()
    getTopNewsData()
    LatestData()
  //   const sidebar = new StickySidebar('.sticky-sidebar', {
  //   topSpacing: 0, // Adjust as needed
  //   bottomSpacing: 0, // Adjust as needed
  // })
  // return () => {

  //   sidebar.destroy();
  //  };
  }, [page, categoryId]);
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
      window.scrollTo({
      top: 0,
      behavior: "smooth"
  })}

  const inputSData=(event)=>{
      localStorage.removeItem(inputSearch);
      setSearch(event.target.value);
    }
    const inputGSData=(event)=>{
      setStartPage(event.target.value)
  
    }
  const onSubmit = (e) => {
      e.preventDefault()
      localStorage.removeItem(inputSearch);
  
     // alert(inputSearch)
      localStorage.setItem('search', inputSearch ? inputSearch :'');
      let path = `/english-search/${inputSearch}`;
      //  SetSHead('template-search')
        //window.reload()
        window.location.pathname=path;
    history.push(path);
      
  
  }
  const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
          setIsVisible(true)
      } else {
          setIsVisible(false)
      }
  }

  const fetchccData = async (page) => {
    let getStories = [];
    setLoading(true);
  
    let getApii = `https://cms.iansnews.in/api/elastic/news/list/?language=english&page=${page}`;
    if(categoryId && categoryId !='all'){
        //cateText = `&tags=${cateText}`;
        getApii += `&tags=${categoryId}`;
    }
    axios.get(getApii)
    .then(res => {
    
   
     getStories =
    res.data.results &&
    res.data.results.length > 0 &&
    res.data.results.map((item) => {
    let tgSt= item.tags.filter(it => it.name === 'Business')
    return {
        byline: item.byline,
        content: item.short_desc,
        id: item.id,
        image: item.thumbnail ? item.thumbnail : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
        image_count:item.image_count,
        dateTime: item.created_at ? formatDate(item.created_at): '',
        title: item.title,
        tags: item.tags,
        slug:'/english-wire-detail/'+item.slug,
        short_desc:item.short_desc,
        video_preview:item.video_url? item.video_url :'',
        image_caption: item.image_caption
    }})

    //let tempArry = engData.push(getStories)
    if(getStories && getStories.length > 0){
        setEngData((engData) => [...engData, ...getStories]);

    }
    setLoading(false);
    })
    .catch(err => {
    // console.log('Google api calendar error', err)
    setLoading(false);
    })    
  };
  const fetchData = async (page) => {
    setLoading(true);
  
    let getApii = `https://cms.iansnews.in/api/elastic/news/list/?language=english&page=${page}`;
    if (categoryId && categoryId !== 'all') {
      getApii += `&tags=${categoryId}`;
    }
  
    try {
      const res = await axios.get(getApii);
  
      const getStories = res.data.results && res.data.results.length > 0
        ? res.data.results.map((item) => {
            return {
              byline: item.byline,
              content: item.short_desc,
              id: item.id,
              image: item.thumbnail ? item.thumbnail : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
              image_count: item.image_count,
              dateTime: item.created_at ? formatDate(item.created_at) : '',
              title: item.title,
              tags: item.tags,
              slug: '/english-wire-detail/' + item.slug,
              short_desc: item.short_desc,
              video_preview: item.video_url ? item.video_url : '',
              image_caption: item.image_caption
            };
          })
        : [];
  
      // Get existing ids from engData
      const existingIds = new Set(engData.map(item => item.id));
  
      // Filter out duplicates
      const uniqueStories = getStories.filter(story => !existingIds.has(story.id));
  
      if (uniqueStories.length > 0) {
        setEngData((prevEngData) => [...prevEngData, ...uniqueStories]);
      }
    } catch (err) {
      // Handle the error
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
                setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  return (
  
  <>
  <Helmet>
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
   <meta data-meta-title="IANS IN" name="keywords" content={''} />
   <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
</Helmet>
<Header currU={'/english-wire/all'}/>

<HomeSlider/>
    <main id="main">
       
        <div className="container video-newsec">
        {/* <Election /> */}
        <Top  title="TOP STORIES" data={topData} vTag={false}/>
        <section id="about" className="about">
        <HeadingTab />
        <div className="row">
                <div className="col-lg-9 order2" id="content1">
                <ul className="top-category-list-bottom">
                    <div className="main-heading">
                    <h2 className="heading">{cateText=='all' ? 'ALL News' : cateText.toUpperCase()}</h2>
                    </div>

                    {/* <div className="ls-poll">
                        <b><a target="_blank" href="https://iansportalimages.s3.amazonaws.com/media/gallantry-awards-2025.pdf">Gallantry awards to 93 Armed Forces personnel announced ahead of R-Day. Here's the complete list</a></b>
                    </div> */}
   
                 { engData && engData.length > 0 && engData.map((engTemp ,et)=>{
                    return(
                        <>
                            <div className="container-fluid sb-4" key={et}  ref={lastElementRef}>
                                <div className="row g-0">
                                    <li>
                                        <figure>
                                            <div className="img-area-top-category-list-bottom">
                                                <NavLink to={engTemp.slug}><img src={engTemp.image} /></NavLink>
                                            </div>
                                            <figcaption className="tabtextarea-top-category-list-bottom">
                                            <NavLink to={engTemp.slug} title={engTemp.title}><h3 className="title">{engTemp.title}</h3></NavLink>
                                                <div className="cat-date-wrap-1">
                                                {engTemp.tags && engTemp.tags.length > 0 && engTemp.tags.map((tgg,t)=>{
                                                    return(
                                                    <span className="cd-cat-M" key={t}><NavLink to={`/english-wire/${tgg.slug}/`}>{tgg.name}</NavLink></span>
                                                )})}
                                                <span className="cd-date-M">{engTemp.dateTime}</span>
                                                </div>
                                                <p>{engTemp.short_desc}</p>
                                            </figcaption>
                                        </figure>
                                    </li>
                                </div>
                            </div>
                        </>
                              )})}
                    {loading && <p>Loading more posts...</p>}
                    </ul>
                </div>
                <div className='col-lg-3 border-left-sec order1 sticky-sidebar'>
                  <div className='sidebar'>
                    <div className="search-form-allphoto input-group">
                      <form onSubmit={onSubmit}>            
                          <input type="search" ref={inputRef} className="form-control" placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                          <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                      </form>
                    </div>
                    <VideoCat data={categoryList} slug={'/english-wire/'} lang={'english'} ref={stickyRef} currU={categoryId ? categoryId : ''}/>
                    </div>
                </div>
          
            </div>
        </section>
        </div>
    </main>               
    <Footer />  
  </>


  );
};

export default EnglishWire;
