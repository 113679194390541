import {NavLink} from 'react-router-dom';
import React, { useEffect } from 'react';
import StickySidebar from 'sticky-sidebar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import Calendar CSS

const VideoCatListShort=(props)=>{
    const isFutureDate = (date) => {
        const today = new Date();
        return date > today;
      };
    // const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
    useEffect(() => {
        const sidebar = new StickySidebar('.sticky-sidebar', {
          topSpacing: 0, // Adjust as needed
          bottomSpacing: 0, // Adjust as needed
        });
    
        return () => {
          sidebar.destroy();
        };
      }, []);
    return(
        <>
            <div className="col-lg-3 vid-right-sec sticky-sidebar">
                <div className='fil-cat'>
                    <div className="filters">
                        {!props.searchDateVal && !props.langg &&
                        <span><i className="bi bi-filter"></i> Filters</span>
                        }
                            {(props.searchDateVal || props.langg) &&
                            <span onClick={props.clearFilter}><i className="bi bi-filter-circle-fill"></i> Clear Filters</span>
                            }
                     </div>
                    <div className="dropdown language-dropdown" style={{ marginTop: '15px'}}>
                        <button className="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        Language | {props.langg ?props.langg.toUpperCase() : ''}
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li onClick={()=>{
                                props.setLanguagge('')
                            }}><NavLink to={`#`}>All</NavLink></li>                                                
                            <li onClick={()=>{
                                props.setLanguagge('english')
                            }}><NavLink to={`#`}>English</NavLink></li>
                            <li onClick={()=>{
                                props.setLanguagge('hindi')
                            }}><NavLink to={`#`}>Hindi</NavLink></li>
                        </ul>
                    </div> 
                </div>                            
                    <div className="main-heading datefilters">
                         <h2 className="heading">Date - {props.searchDateVal ? props.searchDateVal :''}</h2>
                    </div>
                    <div className="app-date">
                        {/* <Calendar onChange={props.onChange} value={props.selectedDate} /> */}
                        <Calendar
                        onChange={props.onChange}
                        value={props.selectedDate}
                        tileDisabled={({ activeStartDate, date, view }) =>
                        view === 'month' && isFutureDate(date)
                        }
                        />
                    </div>                                        
            </div>
        </>
    )
}
export default VideoCatListShort;